import { graphql } from 'gatsby';
import React from 'react';
import { adaptParagraphs } from '@src/adapters/paragraph';
import Paragraph from '@src/components/paragraph';
import LayoutFromContent from '@src/layouts/layout-from-content';
import { CareerQuery } from '@types';
import ContactForm from '@src/components/contact-form';
import styles from './presentation-et-parcours.module.scss';
import SmallParagraph from '@src/components/small-paragraph';

interface CareerProps {
  data: CareerQuery;
}

const CareerPage: React.FC<CareerProps> = ({ data }) => {
  const paragraphsData = adaptParagraphs(data.paragraphs);
  const formationData = paragraphsData.find(e => e.id == 'ma-formation');
  const tetrasData = paragraphsData.find(e => e.id == 'tetras-libre');
  const siriusData = paragraphsData.find(e => e.id == 'trophee-de-sirius');

  return (
    <LayoutFromContent pageId="presentation-et-parcours">
      <div className={styles.page}>
        <div className={styles.container}>
          {formationData && (
            <div className={styles.parcours}>
              <div className={styles.content}>
                <Paragraph paragraph={formationData} />
              </div>
            </div>
          )}

          <div className={styles.parcours}>
            <div className={styles.content}>
              <h2 className={styles.title}>
                Interventions et évènements sportifs
              </h2>
              <div className={styles.contentList}>
                {tetrasData && (
                  <SmallParagraph paragraph={tetrasData} hLevel={3} />
                )}
                {siriusData && (
                  <SmallParagraph paragraph={siriusData} hLevel={3} />
                )}
              </div>
            </div>
          </div>

          <ContactForm />
        </div>
      </div>
    </LayoutFromContent>
  );
};

export const query = graphql`
  query Career {
    paragraphs: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "paragraph" } }
        frontmatter: {
          id: { in: ["ma-formation", "tetras-libre", "trophee-de-sirius"] }
        }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            id
            title
            centered
            image {
              childImageSharp {
                fluid(maxWidth: 330, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            right
          }
        }
      }
    }
  }
`;

export default CareerPage;
