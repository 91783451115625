import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';
import { trackEvent } from '@src/services/analytics';
import { ParagraphData } from '@types';
import styles from './small-paragraph.module.scss';

type SmallParagraphProps = {
  paragraph: ParagraphData;
  hLevel?: number;
  dark?: boolean;
};

const SmallParagraph: React.FC<SmallParagraphProps> = ({
  paragraph,
  hLevel,
  dark,
  children,
}) => {
  const HTag = `h${hLevel}` as keyof JSX.IntrinsicElements;
  return (
    <section className={`${styles.paragraph} ${dark ? styles.dark : ''}`}>
      <div>
        <div className={styles.container}>
          {paragraph.image && (
            <div
              className={`${styles.imageContainer} ${
                paragraph.right ? styles.right : ''
              }`}
            >
              <GatsbyImage
                className={styles.image}
                fluid={paragraph.image}
                alt={paragraph.alt}
              />
            </div>
          )}
          <div
            className={`${styles.textContainer} ${
              paragraph.image ? '' : styles.full
            } ${paragraph.centered === true ? styles.centered : ''}`}
          >
            <HTag className={styles.title}>{paragraph.title}</HTag>
            {children
              ? children
              : paragraph.content && (
                  <div
                    dangerouslySetInnerHTML={{ __html: paragraph.content }}
                  />
                )}
            {paragraph.cta && (
              <Link
                className={styles.link}
                to={paragraph.cta.url}
                onClick={() =>
                  trackEvent('Paragraph', 'Click', paragraph.cta?.url)
                }
              >
                {paragraph.cta.text}
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

SmallParagraph.defaultProps = {
  hLevel: 1,
  dark: false,
};

export default SmallParagraph;
